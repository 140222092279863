<template>
  <CRow v-if="!showLoader" class="fadeIn anim-speed--2s">
    <CCol sm="12">
      <CRow v-if="showLoader" class="justify-content-center">
        <Loader
          width="100px"
          height="100px"
          fill-color="#c2c2c2"
          cssClass="fadeIn anim-speed--1s"
        />
      </CRow>
      <CCardHeader class="flex flex--space-between flex--align-items-baseline">
        <span>
          <strong>Resultados</strong> <slot name="country"></slot>{{ ` ` }}
          <slot name="month"></slot>{{ ` ` }}<slot name="fiscalYear"></slot>
        </span>
      </CCardHeader>
      <CCardHeader hidden>
        <p>
          {{ messages.dailyReport.daysInvoiced }}
          <slot name="invoiceDays"></slot> -
          {{ messages.dailyReport.workingDays }}
          <slot name="workingDays"></slot>
        </p>
      </CCardHeader>
      <CCardHeader>
        <h4 class="card-title">FULL BUSINESS</h4>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :ref="tableId"
          :fields="reactiveFields"
          :items="items"
          :noItemsView="{
            noItems: messages.tables.noItems,
            noResults: messages.tables.noResults,
          }"
          outlined
          border
          clickableRows
          hover
          class="daily-report"
        >
          <template #thead-top>
            <tr class="dark-gray-2">
              <th>
                <span class="sr-only"></span>
              </th>
              <th colspan="3" :style="styles.total.normal">
                <slot name="month"></slot> <slot name="fiscalYear"></slot>
              </th>
              <th :style="styles.total.normal">
                {{ messages.dailyReport.variation }}
              </th>
              <th :style="styles.total.normal">
                {{ messages.dailyReport.monthlyCompletion }}
              </th>
              <th :style="styles.total.normal">
                {{ messages.dailyReport.dailyCompletion }}
              </th>
              <th class="empty-cell"></th>
              <th colspan="3" :style="styles.total.normal">
                {{ messages.filters.months[4] }}
                  a <slot name="month"></slot>
                <slot name="fiscalYear"></slot>
              </th>
              <th :style="styles.total.normal">
                {{ messages.dailyReport.variation }}
              </th>
              <th :style="styles.total.normal">
                {{ messages.dailyReport.monthlyCompletion }}
              </th>
              <th :style="styles.total.normal">
                {{ messages.dailyReport.dailyCompletion }}
              </th>
            </tr>
          </template>
          <template #column-header>
            <th style="background: #ff0000"></th>
          </template>
          <template #description="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.description"
            >
              {{ item.description }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.description"
            >
              {{ item.description }}
            </td>
            <td v-else :style="styles.regular.first">{{ item.description }}</td>
          </template>
          <template #detailcurrentPurchases="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.detailcurrentPurchases }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.detailcurrentPurchases }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.detailcurrentPurchases }}
            </td>
          </template>
          <template #detaillastFyPurchases="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.detaillastFyPurchases }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.detaillastFyPurchases }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.detaillastFyPurchases }}
            </td>
          </template>
          <template #detailtargetPurchases="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.detailtargetPurchases }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.detailtargetPurchases }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.detailtargetPurchases }}
            </td>
          </template>
          <template #detailvariation="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.detailvariation }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.detailvariation }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.detailvariation }}
            </td>
          </template>
          <template #detailmonthlyCompletion="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.detailmonthlyCompletion }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.detailmonthlyCompletion }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.detailmonthlyCompletion }}
            </td>
          </template>
          <template #detaildailyCompletion="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.detaildailyCompletion }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.detaildailyCompletion }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.detaildailyCompletion }}
            </td>
          </template>
          <template #accumDetailcurrentPurchases="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.accumDetailcurrentPurchases }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.accumDetailcurrentPurchases }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.accumDetailcurrentPurchases }}
            </td>
          </template>
          <template #accumDetaillastFyPurchases="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.accumDetaillastFyPurchases }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.accumDetaillastFyPurchases }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.accumDetaillastFyPurchases }}
            </td>
          </template>
          <template #accumDetailtargetPurchases="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.accumDetailtargetPurchases }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.accumDetailtargetPurchases }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.accumDetailtargetPurchases }}
            </td>
          </template>
          <template #accumDetailvariation="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.accumDetailvariation }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.accumDetailvariation }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.accumDetailvariation }}
            </td>
          </template>
          <template #accumDetailmonthlyCompletion="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.accumDetailmonthlyCompletion }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.accumDetailmonthlyCompletion }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.accumDetailmonthlyCompletion }}
            </td>
          </template>
          <template #accumDetaildailyCompletion="{ item }">
            <td
              v-if="item.description.includes('TOTAL')"
              :style="styles.total.normal"
            >
              {{ item.accumDetaildailyCompletion }}
            </td>
            <td
              v-else-if="item.description.includes('MEDIA')"
              :style="styles.average.normal"
            >
              {{ item.accumDetaildailyCompletion }}
            </td>
            <td v-else :style="styles.regular.normal">
              {{ item.accumDetaildailyCompletion }}
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCol>
  </CRow>
</template>

<script>
const Loader = () =>
  import(/* webpackChunkName: "loader" */ "@/components/atoms/Loader");
import TableDataToExcel from "@/helpers/TableDataToExcel";

export default {
  name: "TableFullBusiness",
  components: {
    Loader,
  },
  data() {
    return {
      tableId: "TableFullBusiness",
      styles: {
        total: {
          description:
            "font-weight: bold; color: #ffffff; background: #666666; height: 32px; vertical-align: middle; border-left: 1px solid #dddddd",
          normal:
            "font-weight: bold; color: #ffffff; background: #666666; height: 32px; vertical-align: middle; text-align: center; border-left: 1px solid #dddddd",
        },
        average: {
          description:
            "font-weight: bold; background: #dddddd; height: 32px; vertical-align: middle",
          normal:
            "font-weight: bold; background: #dddddd; height: 32px; vertical-align: middle; text-align: center",
        },
        regular: {
          first: "height: 32px; vertical-align: middle",
          normal: "height: 32px; vertical-align: middle; text-align: center",
        },
      },
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    reactiveFields() {
      const newFields = [];
      this.fields.forEach((elem, index) => {
        let field = { key: elem.key, label: elem.label };
        if (elem.label === "CUSTOM__") field.label = this.customHeader;

        if (index === 7) {
          newFields.push({
            key: "detailempty",
            label: "",
            _classes: "empty-cell",
          });
        }
        newFields.push(field);
      });
      return newFields;
    },
  },
  props: {
    showLoader: {
      type: Boolean,
      required: false,
    },
    fields: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: false,
    },
    customHeader: {
      type: String,
      required: false,
    },
  },
  methods: {
    exportTableToExcel(tableId) {
      TableDataToExcel(
        this.$refs[tableId]._props.fields,
        this.$refs[tableId]._props.items,
        "Full Business"
      );
    },
  },
};
</script>
